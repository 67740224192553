<template>
  <v-card class="ma-4">
    <v-toolbar>
      <v-toolbar-title>SERVE Admin</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <p>This page will allow admins (Student Development staff) to assign students to placements, and to manage those placements (in case the needs change and we need to add more placements or change the supervisors assigned to the placements).</p>
      <p>Admins will also be able to look up any student and see their details and reassign or add times worked.</p>
    </v-card-text>
    <v-tabs v-model="tab">
      <v-tab>Student Assigments</v-tab>
      <v-tab>Supervisors/Placements</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-toolbar>
          <span>Filters will be set here</span>
        </v-toolbar>
        <v-data-table :items="students" :headers="headers"></v-data-table>
      </v-tab-item>
      <v-tab-item>
        <v-card-text>
          <p>This is where the list of placements will be along with the supervisors for each one. This will determine what employees have access to this placement.</p>
          <v-expansion-panels>
            <v-expansion-panel v-for="{ _id: pId, placement, supervisors } in placements" :key="placement">
              <v-expansion-panel-header>{{ placement }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="mb-4">
                  <v-col v-for="{ _id: sId, pidm, name, email } in supervisors" :key="pidm" :cols="12" sm="6" md="4" lg="3">
                    <v-card elevation="3">
                      <v-list>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>{{ name.last }}, {{ name.first }}</v-list-item-title>
                            <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-btn icon color="error" @click="removeSupervisor(pId, sId)">
                              <v-icon>fal fa-times-circle</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-col>
                </v-row>
                <v-dialog v-model="addSupervisorDialog" width="400">
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="success">
                      <v-icon left>fal fa-plus-circle</v-icon>
                      Add Supervisor
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>Add Supervisor</v-card-title>
                    <v-card-text>
                      <directory-search v-model="personSearch" :person-filter="['Faculty','Staff']" clearable />
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="success" text @click="addSupervisor(pId)">Add Supervisor</v-btn>
                      <v-spacer />
                      <v-btn text @click="addSupervisorDialog = false">Cancel</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-dialog v-model="addPlacementDialog" width="400">
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" color="success" class="mt-4">
                <v-icon left>fal fa-plus-circle</v-icon>
                Add Placement
              </v-btn>
            </template>
            <v-card>
              <v-card-title>Add Placement</v-card-title>
              <v-card-text>
                <v-text-field v-model="addPlacementName" label="Placement Name" outlined />
              </v-card-text>
              <v-card-actions>
                <v-btn color="success" text @click="addPlacement">Add Placement</v-btn>
                <v-spacer />
                <v-btn text @click="addPlacementDialog = false">Cancel</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-text>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
import { onMounted, ref } from '@vue/composition-api'

export default {
  components: {
    DirectorySearch: () => import('@/components/greatscots/searchField')
  },
  setup (props, { root }) {
    const tab = ref(0)
    const students = ref([])
    const headers = ref([
      { text: 'Banner ID', value: 'bannerId' },
      { text: 'Last Name', value: 'name.last' },
      { text: 'First Name', value: 'name.first' },
      { text: 'Year', value: 'year' },
      { text: 'Placement', value: 'placement' }
    ])
    async function loadStudents () {
      const aggregate = [
        { $match: { year: { $in: ['FR', 'SO'] } } },
        { $lookup: { from: 'Serve-Student', localField: 'pidm', foreignField: 'pidm', as: 'serve' } },
        { $addFields: { placement: { $first: '$serve' } } },
        { $lookup: { from: 'Serve-Placement', localField: 'placement.placement', foreignField: '_id', as: 'placement' } },
        { $addFields: { placement: '$placement.placement' } },
        { $sort: { 'name.last': 1, 'name.first': 1 } }
      ]
      const { data } = await root.$feathers.service('directory/people').find({ query: { aggregate } })
      students.value = data
    }

    const placements = ref([])
    async function loadPlacements () {
      const { data } = await root.$feathers.service('serve/placement').find({ query: { $populate: 'supervisors' } })
      placements.value = data
    }

    const addPlacementDialog = ref(false)
    const addPlacementName = ref('')
    async function addPlacement () {
      await root.$feathers.service('serve/placement').create({ placement: addPlacementName.value })
      await loadPlacements()
      addPlacementName.value = ''
      addPlacementDialog.value = false
    }

    const addSupervisorDialog = ref(false)
    const personSearch = ref('')
    async function addSupervisor (_id) {
      await root.$feathers.service('serve/placement').patch(_id, { $push: { supervisors: personSearch.value.value } })
      await loadPlacements()
      personSearch.value = null
      addSupervisorDialog.value = false
    }
    async function removeSupervisor (placement, supervisor) {
      const { supervisors: s } = await root.$feathers.service('serve/placement').get(placement)
      const supervisors = s.filter((id) => id !== supervisor)
      await root.$feathers.service('serve/placement').patch(placement, { supervisors })
      loadPlacements()
    }

    onMounted(() => {
      loadStudents()
      loadPlacements()
      // Load the terms for the term dropdown
    })

    return {
      tab,
      students,
      headers,
      loadStudents,
      placements,
      addPlacementDialog,
      addPlacementName,
      addPlacement,
      addSupervisorDialog,
      personSearch,
      addSupervisor,
      removeSupervisor
    }
  }
}
</script>
