var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-4"},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("SERVE Admin")])],1),_c('v-card-text',[_c('p',[_vm._v("This page will allow admins (Student Development staff) to assign students to placements, and to manage those placements (in case the needs change and we need to add more placements or change the supervisors assigned to the placements).")]),_c('p',[_vm._v("Admins will also be able to look up any student and see their details and reassign or add times worked.")])]),_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Student Assigments")]),_c('v-tab',[_vm._v("Supervisors/Placements")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-toolbar',[_c('span',[_vm._v("Filters will be set here")])]),_c('v-data-table',{attrs:{"items":_vm.students,"headers":_vm.headers}})],1),_c('v-tab-item',[_c('v-card-text',[_c('p',[_vm._v("This is where the list of placements will be along with the supervisors for each one. This will determine what employees have access to this placement.")]),_c('v-expansion-panels',_vm._l((_vm.placements),function(ref){
var pId = ref._id;
var placement = ref.placement;
var supervisors = ref.supervisors;
return _c('v-expansion-panel',{key:placement},[_c('v-expansion-panel-header',[_vm._v(_vm._s(placement))]),_c('v-expansion-panel-content',[_c('v-row',{staticClass:"mb-4"},_vm._l((supervisors),function(ref){
var sId = ref._id;
var pidm = ref.pidm;
var name = ref.name;
var email = ref.email;
return _c('v-col',{key:pidm,attrs:{"cols":12,"sm":"6","md":"4","lg":"3"}},[_c('v-card',{attrs:{"elevation":"3"}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(name.last)+", "+_vm._s(name.first))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(email))])],1),_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.removeSupervisor(pId, sId)}}},[_c('v-icon',[_vm._v("fal fa-times-circle")])],1)],1)],1)],1)],1)],1)}),1),_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"success"}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-plus-circle")]),_vm._v(" Add Supervisor ")],1)]}}],null,true),model:{value:(_vm.addSupervisorDialog),callback:function ($$v) {_vm.addSupervisorDialog=$$v},expression:"addSupervisorDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Add Supervisor")]),_c('v-card-text',[_c('directory-search',{attrs:{"person-filter":['Faculty','Staff'],"clearable":""},model:{value:(_vm.personSearch),callback:function ($$v) {_vm.personSearch=$$v},expression:"personSearch"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":function($event){return _vm.addSupervisor(pId)}}},[_vm._v("Add Supervisor")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.addSupervisorDialog = false}}},[_vm._v("Cancel")])],1)],1)],1)],1)],1)}),1),_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-4",attrs:{"color":"success"}},on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("fal fa-plus-circle")]),_vm._v(" Add Placement ")],1)]}}]),model:{value:(_vm.addPlacementDialog),callback:function ($$v) {_vm.addPlacementDialog=$$v},expression:"addPlacementDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Add Placement")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Placement Name","outlined":""},model:{value:(_vm.addPlacementName),callback:function ($$v) {_vm.addPlacementName=$$v},expression:"addPlacementName"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":_vm.addPlacement}},[_vm._v("Add Placement")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.addPlacementDialog = false}}},[_vm._v("Cancel")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }